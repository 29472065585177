<template>
 <div class="box">

    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>

    <model-form
      v-if="model"
      :submit-text="'保存'"
      :model="model"
      :is-edit="false"
      @on-submit="createModel"
    >
    </model-form>

    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import ModelForm from './components/_Form'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import flatry from '@admin/utils/flatry'
import IntegralService from '@admin/services/IntegralService'

export default {
  name: 'RuleIndex',
  data () {
    return {
      pageTitle: '积分规则',
      model: null
    }
  },

  components: { PlaceholderForm, ModelForm },
  async created () {
    let param = {
      invalid_type: 1,
      price_integral: 1,
      is_member_boom: 1, // 会员倍数关联周期
      is_member_birthday: 1, // 生日积分
      birthday_boom: 1, // 生日倍数
      cycle: [ ], // 周期
      shop: [], // 商品
      // 提供数据选择
      level: [], // 会员等级
      shops: [], // 商品列表
      isHave: true
    }
    const { data } = await flatry(IntegralService.rule())

    if (data) {
      if (data.data.rule === 0) {
        param.level = data.data.level
        param.shops = data.data.shops
        param.isHave = false
        this.model = param
      } else {
        this.model = data.data.rule
        this.model.shops = data.data.shops
        this.model.isHave = true
      }
    }
  },

  methods: {
    async createModel (model, success, callback) {
      const { data } = await flatry(IntegralService.rule(model))
      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/integral/rule'
        })
        success()
      }

      callback()
    }
  }
}

</script>
<style lang='' scoped>

</style>
