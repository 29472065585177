<template>
    <div>
        <el-col
            class="b-card"
            :md="9"
        >
            <template v-for="(item,index) in formModel.level">
                <div
                    :key="index"
                    style="width:100%;height:50px;"
                >
                    <el-col
                        style="display:flex;justify-content:space-between;"
                        :md="24"
                    >
                        <span style="width:180px;">
                            {{item.des}} 积分
                        </span>
                        <el-input
                            type="number"
                            style="width:100px;margin:0 10px;"
                            v-model="item.member_level_boom"
                            placeholder=""
                            :min="1"
                        ></el-input>
                        <span>倍</span>
                    </el-col>
                </div>
            </template>
        </el-col>
    </div>
</template>
<script>
export default {
  name: 'RuleMemberBoom',
  props: {
    formModel: {
      type: Object
    }
  },
  data () {
    return {}
  }
}
</script>
<style lang='' scoped>
</style>
