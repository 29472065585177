<template>

  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="200px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')"
  >

    <el-form-item v-if="!formModel.isHave">
      <el-col :span="9">

        <el-alert
          title="当前未存在积分规则,请保存修改"
          type="error"
        >
        </el-alert>
      </el-col>
    </el-form-item>

    <el-col :md="9">
      <div
        class="b-tips"
        style="margin-left:200px;"
      >消费{{formModel.price_integral}}元，获得1积分</div>
    </el-col>

    <el-form-item label='消费'>
      <el-col :md="9">
        <el-input
          v-model="formModel.price_integral"
          placeholder=""
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item label='是否关联会员等级'>
      <template>
        <el-radio-group v-model="formModel.is_member_boom">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
        <member-boom
          :formModel="formModel"
          v-if="formModel.is_member_boom === 1"
        />
      </template>
    </el-form-item>

    <el-form-item label='是否启用生日积分'>
      <template>
        <el-radio-group v-model="formModel.is_member_birthday">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
        <!-- 启用生日积分倍数 -->
        <div v-if="formModel.is_member_birthday === 1">
          <el-col
            class="b-card"
            :md="6"
            style="display:flex;justify-content:space-between;"
          >
            <span style="width:80px;">积分</span>
            <el-input
              type="number"
              style="width:100px;margin:0 10px;"
              v-model="formModel.birthday_boom"
              placeholder=""
              :min="0"
            ></el-input>
            <span>倍</span>
          </el-col>
        </div>
      </template>
    </el-form-item>

    <!-- 关联周期 -->
    <cycle :formModel="formModel" />
    <!-- 特殊商品 -->
    <shop :formModel="formModel" />

    <el-form-item label='积分失效'>
      <template>
        <el-radio-group v-model="formModel.invalid_type">
          <el-radio :label="1">永不失效</el-radio>
          <el-radio :label="2">设置有效天数</el-radio>
          <el-radio :label="3">固定日期失效</el-radio>
        </el-radio-group>
        <!-- 有效天数 -->
        <div v-if="formModel.invalid_type === 2">
          <el-col
            class="b-card"
            :md="7"
            style="display:flex;justify-content:space-between;"
          >
            <span> 有效天数</span>
            <el-input
              type="number"
              style="width:100px"
              v-model="formModel.invalid_body"
              placeholder=""
              :min="1"
            ></el-input>
            <span> 天</span>
          </el-col>
        </div>
        <div v-if="formModel.invalid_type === 3">
          <el-col
            class="b-card"
            :md="9"
            style="display:flex;justify-content:space-between;"
          >
            <span>失效时间</span>
            <el-date-picker
              style="width:200px"
              v-model="formModel.invalid_time"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-col>
        </div>

      </template>
    </el-form-item>

    <el-form-item>
      <el-button
        size="medium"
        type="primary"
        native-type="submit"
        :loading="submitLoading"
      >{{ submitText }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import memberBoom from './member'
import cycle from './cycle'
import shop from './shop'
export default {
  name: 'RuleForm',
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object
    }
  },
  data () {
    return {
      submitLoading: false,
      formRules: {},
      formModel: null
    }
  },

  components: {
    memberBoom,
    cycle,
    shop
  },

  async mounted () {
    this.formModel = Object.assign(this.model)
  },

  methods: {
    handleFormSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }

        this.submitLoading = true
        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  }
}
</script>
<style lang='' scoped>
</style>
