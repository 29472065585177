<template>
  <div>

    <div
      class="b-card"
      style="padding-left:0;"
    >
      <el-form-item label='关联周期'>
        <div class="b-item">
          <el-tag
            v-for="tag in formModel.cycle"
            :key="tag.des"
            closable
            @close="handleClose(tag)"
          >
            {{tag.des}}
          </el-tag>
        </div>
      </el-form-item>
      <el-form-item label='添加周期'>
        <div>
          <el-radio-group v-model="type">
            <el-radio :label="1">每周</el-radio>
            <el-radio :label="2">每月</el-radio>
            <el-radio :label="3">每年</el-radio>
          </el-radio-group>
        </div>
        <!-- 每周 -->
        <div v-if="type === 1">
          <el-select
            style="width:80%"
            v-model="weekBody"
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in weeklyIds"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div
            class="an"
            style="display:flex;"
          >
            积分<el-input
              type="number"
              style="width:80px;margin:0 10px"
              v-model="weekBoom"
              placeholder=""
              :min="1"
            ></el-input> 倍
          </div>
          <div class="an">
            <el-button
              @click="addWeek"
              type="primary" icon="el-icon-plus" plain
            >添加</el-button>
          </div>
        </div>
        <!-- 每月 -->
        <div v-if="type === 2">
          <el-select
            style="width:80%"
            v-model="monthBody"
            multiple
            collapse-tags
            placeholder="请选择时间"
          >
            <el-option
              v-for="item in monthlyIds"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <div
            class="an"
            style="display:flex;"
          >
            积分<el-input
              type="number"
              style="width:100px;margin:0 10px"
              v-model="monthBoom"
              placeholder=""
              :min="1"
            ></el-input> 倍
          </div>
          <div class="an">
            <el-button
              @click="addMonth"
              type="primary" icon="el-icon-plus" plain
            >添加</el-button>
          </div>
        </div>
      </el-form-item>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    formModel: {
      type: Object
    }
  },
  data () {
    const weeklyIds = [
      {
        id: 1,
        name: '周一'
      },
      {
        id: 2,
        name: '周二'
      },
      {
        id: 3,
        name: '周三'
      },
      {
        id: 4,
        name: '周四'
      },
      {
        id: 5,
        name: '周五'
      },
      {
        id: 6,
        name: '周六'
      },
      {
        id: 7,
        name: '周日'
      }
    ]
    const monthlyIds = [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31
    ]
    return {
      type: 1,
      weekBody: [],
      weekBoom: '', // 倍数
      weeklyIds: weeklyIds, // 每周可用
      monthBody: [],
      monthBoom: '', // 倍数
      monthlyIds: monthlyIds // 每月
    }
  },
  methods: {
    handleClose (tag) {
      this.formModel.cycle.splice(this.formModel.cycle.indexOf(tag), 1)
    },
    // 每周
    addWeek () {
      if (this.weekBody.length < 1) {
        this.$message.warning('请选择时间')
        return false
      }
      if (this.weekBoom < 1) {
        this.$message.warning('请输入积分倍数')
        return
      }

      let week = '每周'
      let b = ''
      this.weekBody.forEach(i => {
        b += i + '、'
      })

      let arr = {
        body: this.weekBody,
        boom: this.weekBoom,
        type: this.type,
        des: week + b + '(' + this.weekBoom + ')倍'
      }
      this.formModel.cycle.push(arr)
      this.weekBody = []
      this.weekBoom = ''
    },
    // 每月
    addMonth () {
      if (this.monthBody.length < 1) {
        this.$message.warning('请选择时间')
        return false
      }
      if (this.monthBoom < 1) {
        this.$message.warning('请输入正确的倍数')
        return false
      }

      let week = '每月'

      let b = ''
      this.monthBody.forEach(i => {
        b += i + '、'
      })

      let arr = {
        body: this.monthBody,
        boom: this.monthBoom,
        type: this.type,
        des: week + b + '(' + this.monthBoom + ')倍'
      }
      this.formModel.cycle.push(arr)
      this.monthBody = []
      this.monthBoom = ''
    }
  }
}
</script>
<style lang='scss' scoped>
.an {
    margin-top: 30px;
}
.b-item {
    .el-tag + .el-tag {
        margin-left: 24px;
    }
}
</style>
