<template>
  <div style="margin:40px 0;">
    <div
      class="b-card"
      style="padding-left:0;"
    >

      <el-form-item label='特殊商品积分'>
        <div class="b-item">
          <el-tag
            v-for="tag in formModel.shop"
            :key="tag.des"
            closable
            @close="handleClose(tag)"
          >
            {{tag.des}}
          </el-tag>
        </div>

      </el-form-item>

      <el-form-item label='商品名称'>
        <div>
          <el-select
            style="width:30%"
            v-model="shop_id"
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in formModel.shops"
              :key="item.id"
              :label="item.product_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div>

        </div>
      </el-form-item>

      <el-form-item label='获得积分'>
        <el-col :span="5">
          <el-input v-model="get_integral" placeholder=""></el-input>
        </el-col>

      </el-form-item>

      <el-form-item>
         <div class="an">
            <el-button
              @click="addShop"
              type="primary" icon="el-icon-plus" plain
            >添加</el-button>
          </div>
      </el-form-item>
    </div>

  </div>
</template>

<script>
export default {
  name: '',
  props: {
    formModel: {
      type: Object
    }
  },
  data () {
    return {
      shop_id: [],
      get_integral: ''
    }
  },
  methods: {
    handleClose (tag) {
      this.formModel.shop.splice(this.formModel.shop.indexOf(tag), 1)
    },
    addShop () {
      if (this.shop_id.length < 1) {
        this.$message.warning('请选择商品')
        return false
      }
      if (this.get_integral < 1) {
        this.$message.warning('请输入获得积分')
        return false
      }
      let shopName = ''
      this.formModel.shops.forEach(element => {
        this.shop_id.forEach(i => {
          if (i === element.id) {
            shopName += element.product_name + '、'
          }
        })
      })
      let arr = {
        shop_id: this.shop_id,
        get_integral: this.get_integral,
        des: shopName + this.get_integral + ' 积分'
      }
      this.formModel.shop.push(arr)
      this.shop_id = []
      this.get_integral = ''
    }
  }
}
</script>
<style lang='scss' scoped>
.b-item {
    .el-tag + .el-tag {
        margin-left: 24px;
    }
}
</style>
